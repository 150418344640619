// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-showcase-tsx": () => import("./../../../src/templates/showcase.tsx" /* webpackChunkName: "component---src-templates-showcase-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */),
  "component---src-templates-vacature-tsx": () => import("./../../../src/templates/vacature.tsx" /* webpackChunkName: "component---src-templates-vacature-tsx" */)
}

